var Callout = {

  el: '.callout',

  ui: {
    'close': '.callout__close',
    'input': '.sticker-email-signup__email'
  },

  initialize() {
    this.flag = document.querySelector('.by-menu__flag');
    this.windowHeight = window.innerHeight;

    if (this.el.classList.contains('-home')) {
      if (window.innerWidth >= 768) {
        window.addEventListener('scroll', this.throttle(this.scroll.bind(this), 350));
      }
    } else if (this.flag) {
      this.flag.addEventListener('click', this.showCallout.bind(this));
      this.ui.$close.get(0).addEventListener('click', this.hideCallout.bind(this));
    }
  },

  scroll(e) {
    var top = document.body.scrollTop || document.documentElement.scrollTop;
    if (top > 470) {
      this.el.classList.remove('-active');
    } else {
      this.el.classList.add('-active');
    }
  },

  hideCallout(e) {
    this.el.classList.remove('-active');
    this.flag.classList.remove('-hidden');
    this.flag.focus();
  },

  showCallout(e) {

    e.currentTarget.classList.add('-hidden');
    setTimeout(function() {
      this.el.classList.add('-active');
      this.ui.$input.get(0).focus();
    }.bind(this), 50);
  },

  throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function() {
      previous = options.leading === false ? 0 : new Date().getTime();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    return function() {
      var now = new Date().getTime();
      if (!previous && options.leading === false) previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        clearTimeout(timeout);
        timeout = null;
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  }

};

module.exports = Callout;
