var qs            = require('query-string');
var Cookies       = require('cookies-js');
var params        = qs.parse(window.location.search);

module.exports = {

  sources: ['s_src', 's_subsrc'],

  getSources: function(sources = this.sources) {
    return qs.stringify(sources.reduce((obj, source) => {
      var value = Cookies.get(source);
      if (value) {
        obj[source] = value;
      }
      return obj;
    }, {}));
  },

  setSources: function(sources = this.sources) {
    sources.forEach((source) => {
      if (source in params) {
        Cookies.set(source, params[source]);
      }
    });
  }

};
